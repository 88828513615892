'use client'

import {
  createContext,
  Dispatch,
  forwardRef,
  HTMLAttributes,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import Link from 'next/link'
import { ArticleSchema } from '@/contentful/shared'

import { contentConfig } from '@/config/content'
import {
  cn,
  removeDuplicateSubcategories,
  sortBySubcategory,
} from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  ArticleCard,
  ArticleCardCategory,
  ArticleCardContent,
  ArticleCardHeader,
  ArticleCardHeaderText,
  ArticleCardImage,
  ArticleCardLink,
  ArticleCardTags,
  ArticleCardTitle,
} from '@/components/article-card'
import { getArticlesByCategory } from '@/app/(marketing)/categories/[categoryId]/actions'

import { AutoComplete, type Option } from './autocomplete'
import { Label } from './ui/label'

type ArticleGridProps = HTMLAttributes<HTMLDivElement> & {
  initialArticles: ArticleSchema[]
  totalArticles: number
  category: string
  searchOptions: Option[]
  type?: 'category' | 'subcategory'
}

type ArticleGridContextProps = {
  articles: ArticleSchema[]
  visibleArticles: ArticleSchema[]
  handleLoadMoreArticles: (limit: number) => void
  setSubCategoryFilter: Dispatch<SetStateAction<string>>
  showLoadMore: boolean
  setShowLoadMore: Dispatch<SetStateAction<boolean>>
} & ArticleGridProps

const ArticleGridContext = createContext<ArticleGridContextProps | null>(null)

function useGrid() {
  const context = useContext(ArticleGridContext)

  if (!context) {
    throw new Error('useGrid must be used within a <Grid />')
  }

  return context
}

const ArticleGrid = forwardRef<HTMLDivElement, ArticleGridProps>(
  (
    {
      className,
      children,
      initialArticles,
      totalArticles,
      category,
      searchOptions,
      type,
      ...props
    },
    ref
  ) => {
    const [articles, setArticles] = useState<ArticleSchema[]>(initialArticles)
    const [subCategoryFilter, setSubCategoryFilter] = useState('')
    const [visibleCount, setVisibleCount] = useState(6)
    const [showLoadMore, setShowLoadMore] = useState(true)

    const filteredArticles = articles.filter((article: ArticleSchema) =>
      sortBySubcategory(article.fields.subCategory)?.some(
        (subCategoryMap: string) => {
          const subCategory = subCategoryMap.split(':')[1].toLowerCase()
          return subCategory.includes(subCategoryFilter.toLowerCase())
        }
      )
    )

    const visibleArticles = filteredArticles
      .slice(0, visibleCount)
      .map((article) => ({
        ...article,
        subCategory: removeDuplicateSubcategories(
          article.fields.subCategory,
          article.fields.category
        ),
      }))

    useEffect(() => {
      if (subCategoryFilter) {
        const search = contentConfig.findSubcategories(subCategoryFilter) || []
        const occurrences = contentConfig.countExisting(visibleArticles, search)
        handleLoadMoreArticles(6 - occurrences)
      }
    }, [subCategoryFilter])

    useEffect(() => {
      const fetchTotalArticles = async () => {
        if (subCategoryFilter) {
          const { total } = await getArticlesByCategory({
            search: contentConfig.findSubcategories(subCategoryFilter),
            category,
          })
          setShowLoadMore(visibleArticles.length < total)
        } else {
          setShowLoadMore(articles.length < totalArticles)
        }
      }

      fetchTotalArticles()
    }, [subCategoryFilter, articles])

    const handleLoadMoreArticles = async (limit: number) => {
      const { articles: entries } = await getArticlesByCategory({
        search: contentConfig.findSubcategories(subCategoryFilter),
        fetchedSlugs: articles.map((article) => article.fields.slug),
        category,
        limit,
        skip: type === 'subcategory' ? 3 : 0,
      })

      if (entries.length) {
        setArticles((prev: ArticleSchema[] | undefined) => [
          ...(prev?.length ? prev : []),
          ...entries,
        ])
        setVisibleCount((prevCount) => prevCount + limit)
      }
    }

    const contextValue = {
      articles,
      initialArticles,
      setSubCategoryFilter,
      setShowLoadMore,
      visibleArticles,
      totalArticles,
      category,
      searchOptions,
      handleLoadMoreArticles,
      showLoadMore,
    }
    return (
      <ArticleGridContext.Provider value={contextValue}>
        <div
          ref={ref}
          className={cn('p-5 md:px-8 md:py-7 lg:px-11 lg:py-8', className)}
          {...props}
        >
          {children}
        </div>
      </ArticleGridContext.Provider>
    )
  }
)

ArticleGrid.displayName = 'ArticleGrid'

const ArticleGridHeading = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('heading-lg-regular pb-7', className)}
    {...props}
  />
))

ArticleGridHeading.displayName = 'ArticleGridHeading'

const ArticleGridSearch = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  const {
    totalArticles,
    articles,
    searchOptions,
    setSubCategoryFilter,
    setShowLoadMore,
  } = useGrid()
  const [value, setValue] = useState<Option>()

  return (
    <div
      ref={ref}
      className={cn('pb-7 lg:pb-9 flex flex-col gap-y-2', className)}
      {...props}
    >
      <Label htmlFor="article-search">{children}</Label>
      <AutoComplete
        options={searchOptions}
        emptyMessage="No results found."
        onValueChange={(event) => {
          setValue(event)
          setSubCategoryFilter(event.value)
        }}
        onClear={() => {
          setSubCategoryFilter('')
          setShowLoadMore(articles.length < totalArticles)
        }}
        value={value}
        disabled={false}
        id="article-search"
      />
    </div>
  )
})

ArticleGridSearch.displayName = 'ArticleGridSearch'

const ArticleGridList = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className }, ref) => {
  const { visibleArticles, handleLoadMoreArticles, showLoadMore } = useGrid()
  return (
    <>
      <div
        ref={ref}
        className={cn(
          'grid auto-rows-fr grid-cols-1 justify-items-center gap-y-6 sm:gap-x-4 sm:gap-y-7 md:grid-cols-2 xl:grid-cols-[repeat(3,400px)] xl:gap-x-8',
          className
        )}
      >
        {visibleArticles?.map((article) => {
          const { headline, slug, asset, category, subCategory } =
            article.fields
          return (
            <ArticleCard key={slug} variant="vertical">
              <ArticleCardImage
                src={asset?.fields.file?.url || ''}
                alt={asset?.fields.description || ''}
              />
              <ArticleCardContent>
                <ArticleCardHeader>
                  <ArticleCardHeaderText>
                    <ArticleCardCategory>{category}</ArticleCardCategory>
                    <ArticleCardTitle>{headline}</ArticleCardTitle>
                  </ArticleCardHeaderText>
                  <ArticleCardTags>
                    {subCategory?.map((subcat) => {
                      const [category, subcategory] = subcat.split(':')
                      return (
                        <Link
                          href={contentConfig.subcategoryPath(
                            category,
                            subcategory
                          )}
                          key={`${category}-${subcat}`}
                        >
                          <Badge>{subcategory}</Badge>
                        </Link>
                      )
                    })}
                  </ArticleCardTags>
                </ArticleCardHeader>
                <ArticleCardLink href={contentConfig.articlePath(slug)}>
                  Read now
                </ArticleCardLink>
              </ArticleCardContent>
            </ArticleCard>
          )
        })}
      </div>
      {showLoadMore && (
        <Button
          className="mt-7 w-full lg:mt-9"
          variant={'primary-outline'}
          onClick={() => {
            handleLoadMoreArticles(6)
          }}
          data-testid="load-more-button"
        >
          Load more
        </Button>
      )}
    </>
  )
})

ArticleGridList.displayName = 'ArticleGridList'

export { ArticleGrid, ArticleGridHeading, ArticleGridList, ArticleGridSearch }
